html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
snap-content,
[snap\:content],
[snap-content],
[data-snap-content],
[x-snap-content],
.snap-content,
.x-snap-content,
snap-drawers,
[snap\:drawers],
[snap-drawers],
[data-snap-drawers],
[x-snap-drawers],
.snap-drawers,
.x-snap-drawers,
.snap-drawers,
.snap-content {
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  width: auto;
  left: 0;
  right: 0;
}
snap-drawer,
[snap\:drawer],
[snap-drawer],
[data-snap-drawer],
[x-snap-drawer],
.snap-drawer,
.x-snap-drawer,
.snap-drawer {
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  overflow: auto;
  -webkit-transition: top 0.2s linear;
  -moz-transition: top 0.2s linear;
  -o-transition: top 0.2s linear;
  transition: top 0.2s linear;
  -webkit-transition: bottom 0.2s linear;
  -moz-transition: bottom 0.2s linear;
  -o-transition: bottom 0.2s linear;
  transition: bottom 0.2s linear;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  overflow-scrolling: touch;
  width: 265px;
}
.snap-content {
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
.snap-drawer-left {
  left: 0;
  z-index: 1;
}
.snap-drawer-right {
  right: 0;
  z-index: 1;
}
.snapjs-left .snap-drawer-right,
.snapjs-right .snap-drawer-left {
  display: none;
}
.snapjs-expand-left .snap-drawer-left,
.snapjs-expand-right .snap-drawer-right {
  width: 100%;
}
